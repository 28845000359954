<template>
  <v-menu
    ref="menu"
    v-model="menuOpen"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="290px">
    <template #activator="{ on }">
      <v-text-field
        v-model="time"
        :label="label"
        :prepend-icon=" prependIcon ? 'access_time': ''"
        :error-messages="errorMessages"
        readonly
        required
        class="required-field"
        @blur="$emit('blur')"
        v-on="on"/>
    </template>
    <v-time-picker
      v-model="time"
      format="24hr"
      color="secondary"
      required
      class="required-field"
      @change="$refs.menu.save(time)"/>
  </v-menu>
</template>

<script>
export default {
  name: 'CyInputsTimeSelect',
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default: () => [],
    },
  },
  data: ({ value }) => ({
    menuOpen: false,
    time: value,
  }),
  watch: {
    time (value) {
      this.$emit('input', value)
    },
  },
}
</script>
