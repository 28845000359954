<template>
  <div class="d-flex px-8">
    <div class="label pr-6">
      {{ $t('cronFrequency') }}
    </div>
    <v-select
      v-model="frequency"
      :label="$t('fieldFrequency')"
      :items="$static.types"
      :menu-props="{ maxHeight: '352' }"
      required
      class="required-field frequency-choice"/>

    <div
      v-if="frequency === 'hour'"
      class="frequency-parameters">
      <div class="label px-6">
        {{ $t('cronAtHour') }}
      </div>
      <v-autocomplete
        v-model="$v.config.hour.minute.$model"
        :label="$t('fieldMinutes')"
        :items="$static.minutes"
        :error-messages="getErrors('hour', 'minute')"
        required
        class="required-field"
        @blur="$v.config.hour.minute.$touch()"/>
    </div>

    <div
      v-if="frequency === 'day'"
      class="frequency-parameters">
      <div class="label px-6">
        {{ $t('cronAtDay') }}
      </div>
      <CyInputsTimeSelect
        v-model="$v.config.day.hour.$model"
        :label="$t('fieldHour')"
        :error-messages="getErrors('day', 'hour')"
        required
        class="required-field"
        @blur="$v.config.day.hour.$touch()"/>
    </div>

    <div
      v-if="frequency === 'week'"
      class="frequency-parameters">
      <div class="label px-6">
        {{ $t('cronAtWeek') }}
      </div>
      <v-autocomplete
        v-model="$v.config.week.day.$model"
        :label="$t('fieldDayOfWeek')"
        :items="$static.daysOfWeek"
        :error-messages="getErrors('week', 'day')"
        required
        class="required-field mr-4"
        @blur="$v.config.week.day.$touch()"/>
      <CyInputsTimeSelect
        v-model="$v.config.week.hour.$model"
        :label="$t('fieldHour')"
        :error-messages="getErrors('week', 'hour')"
        required
        class="required-field pl-4"
        @blur="$v.config.week.hour.$touch()"/>
    </div>

    <div
      v-if="frequency === 'month'"
      class="frequency-parameters">
      <div class="label px-6">
        {{ $t('cronAtMonth') }}
      </div>
      <v-autocomplete
        v-model="$v.config.month.day.$model"
        :label="$t('fieldDayOfMonth')"
        :items="$static.daysOfMonth"
        :error-messages="getErrors('month', 'day')"
        required
        class="required-field mr-4"
        @blur="$v.config.month.day.$touch()"/>
      <CyInputsTimeSelect
        v-model="$v.config.month.hour.$model"
        :label="$t('fieldHour')"
        :error-messages="getErrors('month', 'hour')"
        required
        class="required-field"
        @blur="$v.config.month.hour.$touch()"/>
    </div>

    <div
      v-if="frequency === 'year'"
      class="frequency-parameters">
      <div class="label px-6">
        {{ $t('cronAtYear') }}
      </div>
      <v-autocomplete
        v-model="$v.config.year.day.$model"
        :label="$t('fieldDayOfMonth')"
        :items="$static.daysOfMonth"
        :error-messages="getErrors('year', 'day')"
        required
        class="required-field mr-4 month-picker"
        @blur="$v.config.year.day.$touch()"/>
      <v-autocomplete
        v-model="$v.config.year.month.$model"
        :label="$t('fieldMonth')"
        :items="$static.months"
        :error-messages="getErrors('year', 'month')"
        required
        class="required-field mr-4"
        @blur="$v.config.year.month.$touch()"/>
      <div class="year-hour-picker">
        <CyInputsTimeSelect
          v-model="$v.config.year.hour.$model"
          :label="$t('fieldHour')"
          :error-messages="getErrors('year', 'hour')"
          required
          class="required-field"
          @blur="$v.config.year.hour.$touch()"/>
      </div>
    </div>
  </div>
</template>

<script>
import CyInputsTimeSelect from '@/components/inputs/time-select.vue'
import moment from 'moment' // eslint-disable-line you-dont-need-momentjs/no-import-moment
import { requiredIf } from 'vuelidate/lib/validators'

const initial = {
  frequency: 'day',
  config: {
    hour: {
      minute: null,
    },
    day: {
      hour: null,
    },
    week: {
      day: null,
      hour: null,
    },
    month: {
      day: null,
      hour: null,
    },
    year: {
      day: null,
      month: null,
      hour: null,
    },
  },
}

export default {
  name: 'CyInputsCronPicker',
  components: {
    CyInputsTimeSelect,
  },
  validations () {
    const { frequency } = this

    function isRequired (field) {
      return requiredIf(() => _.isEqual(frequency, field))
    }

    return {
      config: {
        hour: {
          minute: { required: isRequired('hour') },
        },
        day: {
          hour: { required: isRequired('day') },
        },
        week: {
          hour: { required: isRequired('week') },
          day: { required: isRequired('week') },
        },
        month: {
          hour: { required: isRequired('month') },
          day: { required: isRequired('month') },
        },
        year: {
          hour: { required: isRequired('year') },
          day: { required: isRequired('year') },
          month: { required: isRequired('year') },
        },
      },
    }
  },
  data: () => ({
    frequency: initial.frequency,
    config: _.cloneDeep(initial.config),
  }),
  computed: {
    $static () {
      return {
        types: ['minute', 'hour', 'day', 'week', 'month', 'year'].map((value) => ({ text: this.$t(`cronType_${value}`), value })),
        months: moment.months(),
        daysOfWeek: moment.weekdays(),
        minutes: _.times(60).map((value) => String(value).padStart(2, '0')),
        daysOfMonth: _.times(32).map((value) => ({ text: this.$t('dayOfMonth', { count: value }), value })),
      }
    },
  },
  watch: {
    frequency () {
      this.generateCron()
      this.$v.config.$reset()
    },
    config: {
      handler () {
        this.generateCron()
      },
      deep: true,
    },
  },
  mounted () {
    this.generateCron()
  },
  methods: {
    getErrors (freq, field) {
      const errors = []
      const { $dirty, required } = this.$v.config[freq][field]
      if (!$dirty) return errors
      if (!required) errors.push(this.$t('forms.fieldRequired'))
      return errors
    },
    generateCron () {
      const dayOfWeek = this.$static.daysOfWeek.indexOf(this.config.week.day)
      const month = this.$static.months.indexOf(this.config.year.month) + 1
      const hours = _.$get(this.config, `${this.frequency}.hour`, '').split(':')[0]
      const minutes = _.$get(this.config, `${this.frequency}.hour`, '').split(':')[1]

      const cron = {
        minute: '* * * * *',
        hour: `${this.config.hour.minute} * * * *`,
        day: `${minutes} ${hours} * * *`,
        week: `${minutes} ${hours} * * ${dayOfWeek}`,
        month: `${minutes} ${hours} ${this.config.month.day} * *`,
        year: `${minutes} ${hours} ${this.config.year.day} ${month} *`,
      }

      this.$emit('input', cron[this.frequency])
    },
  },
  i18n: {
    messages: {
      en: {
        cronAtDay: 'at',
        cronAtHour: 'at',
        cronAtMonth: 'on the',
        cronAtWeek: 'on',
        cronAtYear: 'on the',
        cronFrequency: 'Every',
        cronType_day: 'day',
        cronType_hour: 'hour',
        cronType_minute: 'minute',
        cronType_month: 'month',
        cronType_week: 'week',
        cronType_year: 'year',
        dayOfMonth: '#{count} day',
        fieldDayOfMonth: 'Day of month',
        fieldDayOfWeek: 'Day of week',
        fieldDayOfYear: 'Day of year',
        fieldFrequency: 'Frequency',
        fieldHour: 'Hour',
        fieldMinutes: 'Minutes',
        fieldMonth: 'Month',
      },
      es: {
        cronAtDay: 'a',
        cronAtHour: 'a',
        cronAtMonth: 'al',
        cronAtWeek: 'los',
        cronAtYear: 'al',
        cronFrequency: 'Cada',
        cronType_day: 'día',
        cronType_hour: 'hora',
        cronType_minute: 'minuto',
        cronType_month: 'mes',
        cronType_week: 'semana',
        cronType_year: 'año',
        dayOfMonth: '{count}º día',
        fieldDayOfMonth: 'Día del mes',
        fieldDayOfWeek: 'Día de la semana',
        fieldDayOfYear: 'Día del año',
        fieldFrequency: 'Frecuencia',
        fieldHour: 'Hora',
        fieldMinutes: 'Minutos',
        fieldMonth: 'Mes',
      },
      fr: {
        cronAtDay: 'à',
        cronAtHour: 'à',
        cronAtMonth: 'le',
        cronAtWeek: 'le',
        cronAtYear: 'le',
        cronFrequency: 'À chaque',
        cronType_day: 'jour',
        cronType_hour: 'heure',
        cronType_minute: 'minute',
        cronType_month: 'mois',
        cronType_week: 'semaine',
        cronType_year: 'année',
        dayOfMonth: '#{count} jour',
        fieldDayOfMonth: 'Jour',
        fieldDayOfWeek: 'Jour de la semaine',
        fieldDayOfYear: `Jour de l'année`,
        fieldFrequency: 'Fréquence',
        fieldHour: 'Heure',
        fieldMinutes: 'Minutes',
        fieldMonth: 'Mois',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
  .label {
    flex-shrink: 0;
    margin-top: 21px;
    color: cy-get-color("black");
    font-size: 14px;
  }

  .frequency {
    &-choice {
      flex-grow: 0;
      flex-shrink: 0;
      width: 100px;
    }

    &-parameters {
      display: flex;

      .year-hour-picker {
        flex-shrink: 0;
        width: 60px;
      }

      > :not(.label) {
        flex-grow: 1;
        max-width: 140px;
      }

      .label {
        width: 90px;
      }
    }
  }
</style>
